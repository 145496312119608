<template>
  
     <SocialTools2></SocialTools2>


       <ListHeader
    :defaultSort="defaultSort"
        @input="onInput"
        @change="onSort"
    ></ListHeader>

    <div class="layout-list-content infotronik_social_pageslist">
        <ul class="layout-list nopadding nomargin">
            <SocialListElement 
                v-for="social in socials" 
                :key="social.socialId" 
                :social="social" 
                :selectedId="selectedId"
            ></SocialListElement>
        </ul>
    </div>

   
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import SocialListElement from '@/components/social/SocialListElement'
import SocialTools2 from '@/components/social/SocialTools2'
import ListHeader from '@/components/ListHeader'
import Pagination from '@/components/Pagination'


export default {
    props:{
        socials:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:Number,
            default:null
        },
        defaultSort: {
            type: String,
            default: 'Nome asc'
        }
    },
    async mounted() {
        const defaultSorting = { type: 'Nome asc' };
        this.onSort({ target: { value: defaultSorting } });
    },
    data() {
        return {
            selectedSort: this.defaultSort
        }
    },
    components:{
        SocialListElement,
        ListHeader,
        Pagination,
        SocialTools2
    },
    setup(){
        const store = useStore()
        const searchbox = ref(null)
        
        const onInput = (filter)=>{
            store.dispatch('social/filter', filter) 
        }
        const onSort =(sorting)=>{        
            store.dispatch('social/sort', sorting)
        }
        const pagination = computed(()=>{
            return store.getters['social/pagination']
        })
        const onPageChange = async (page)=>{
            await store.dispatch('social/list', {PageNumber:page})
        }

        return{
            searchbox,
            onInput,
            onSort,
            pagination,
            onPageChange,
            SocialTools2
        
        }
    }
}
</script>