<template>
  <div class="mb8 infotronik_social_macroblocco">
    <div
      class="infotronik_social_macroblocco_interno infotronik_macroblocco_interno_grafici"
    >
      <h2 class="nomargin bebas infotronik_social_macroblocco_titolo">
        ETA' E GENERE
      </h2>
      <div
        id="infotronik_social_macroblocco_graficigenderage"
        class="infotronik_social_macroblocco_interno_contenitore"
      >
        <div class="insight_pieChart">
          <DoughnutChart :chartData="testData" class="pieChart" />
        </div>
        <br /><br />
        <div class="insight_pieChart">
          <BarChart
            :chartData="testData2"
            :options="options_genderage"
            class="barChart infotronik_genderage_bars"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mb8 infotronik_social_macroblocco">
    <div
      class="infotronik_social_macroblocco_interno infotronik_macroblocco_interno_grafici"
    >
      <h2 class="nomargin bebas infotronik_social_macroblocco_titolo">
        CITTA' PRINCIPALI
      </h2>
      <div
        id="infotronik_social_macroblocco_grafifancities"
        class="infotronik_social_macroblocco_interno_contenitore"
      >
        <div class="insight_pieChart">
          <BarChart
            :chartData="testData3"
            :options="options_fancities"
            class="barChart infotronik_fancities_bars"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { onMounted } from "vue";
import { DoughnutChart } from "vue-chart-3";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import {useCapabilities} from '@/utils/Capabilities'

Chart.register(...registerables);

import { ApiEndPoints } from "@/api/Endpoints";

export default {
  //name: 'BalzaTestArea',
  props: ["caller", "sociaPagelId"],
  data() {
    return {
      testData: null,
      testData2: null,
      testData3: null,
    };
  },
  created() {
    this.testData = {
      labels: ["Donne", "Uomini", "Altro"],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ["#77CEFF", "#0079AF", "#00A9AF"],
        },
      ],
    };

    this.testData2 = {
      labels: [
        "18-24",
        "18-24",
        "25-34",
        "25-34",
        "35-44",
        "35-44",
        "45-54",
        "45-54",
        "55-64",
        "55-64",
        "65+",
        "65+",
      ],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            "#77CEFF",
            "#0079AF",
            "#77CEFF",
            "#0079AF",
            "#77CEFF",
            "#0079AF",
            "#77CEFF",
            "#0079AF",
            "#77CEFF",
            "#0078AF",
            "#75CEFF",
            "#0079AF",
          ],
        },
      ],
    };

    this.options_genderage = {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };

    this.testData3 = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            "#77CEFF",
            "#0079AF",
            "#123E6B",
            "#97B0C4",
            "#A5C8ED",
            "#A5F8ED",
            "#75CEFF",
            "#0F79AF",
            "#123F6B",
            "#F7B0C4",
          ],
        },
      ],
    };

    this.options_fancities = {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };

    this.getPageInsights();

    this.emitter.on("aggirnadatiInsights", () => {
      const ora = Date.now();
      if (!this.ultimoClick || ora - this.ultimoClick > 300000) {
        this.ultimoClick = ora;
        this.updatePageInsights();
        // Esegui la tua funzione qui
        document
          .getElementById("aggiorna")
          .classList.add("infotronik_social_pulsanteaggiorna_disable");
      } else {
        document
          .getElementById("aggiorna")
          .classList.remove("infotronik_social_pulsanteaggiorna_disable");
      }
    });
  },
  mounted() {},

  methods: {
    //appena entrati chiamo API con timestamp

    getPageInsights() {
      var data = Date.now();
      var timestamp = Math.floor(data / 1000);
      var giornimassimi = -1; // -1 = tutto, N (>= 0) = giorni massimi indietro

      const { isPublisher, isConcessionaire, isAdvertiser } = useCapabilities()
      const usePublisherController = isPublisher()||isConcessionaire()
      const useAdvertiserController = isAdvertiser()

      const caller_string = usePublisherController ? `${ApiEndPoints.PUBLISHERS}/${this.sociaPagelId}/insights/${timestamp}/${giornimassimi}` : useAdvertiserController ? `${ApiEndPoints.ADVERTISERS}/${this.sociaPagelId}/insights/${timestamp}/${giornimassimi}` : `${ApiEndPoints.SOCIAL_FB}/${this.sociaPagelId}/insights/${timestamp}/${giornimassimi}`
      // Make a GET request to the API
      axios
        .get(caller_string)
        .then((response) => {
          // Gestisci la risposta

          let females = {
            total: 0,
            "18-24": 0,
            "25-34": 0,
            "35-44": 0,
            "45-54": 0,
            "55-64": 0,
            "65+": 0,
          };
          let males = { ...females };
          let sex_not_found = { ...females };

          if (response.data.fansGenderAge == null) {
            //se non torna nulla
            // TODO risposta vuota, non disegnare il grafico
          } else {
            /// se torna qualcosa
            //SESSO///
            var fansGenderAgeArray = Object.entries(
              response.data.fansGenderAge
            );
            fansGenderAgeArray.forEach((value, key) => {
              var parts = fansGenderAgeArray[key].toString().split(".");
              // Determino il sesso:
              let ref;
              switch (parts[0]) {
                case "f":
                  ref = females;
                  break;
                case "m":
                  ref = males;
                  break;
                default:
                  ref = sex_not_found;
                  break;
              }
              // Assegno il totale
              ref.total += fansGenderAgeArray[key][1];
              // Assegno alla categoria

              let age_key = parts[1].split(",")[0]; // recupero la chiave per l'oggetto (es. 13-17, 18-24, etc..)
              ref[age_key] += fansGenderAgeArray[key][1];
            });

            // aggirno il grafico Torta
            this.testData.datasets[0].data[0] = females.total;
            this.testData.datasets[0].data[1] = males.total;
            this.testData.datasets[0].data[2] = sex_not_found.total;

            //aggirno il grafico
            this.testData2.datasets[0].data[0] = females["18-24"];
            this.testData2.datasets[0].data[1] = males["18-24"];
            this.testData2.datasets[0].data[2] = females["25-34"];
            this.testData2.datasets[0].data[3] = males["25-34"];
            this.testData2.datasets[0].data[4] = females["35-44"];
            this.testData2.datasets[0].data[5] = males["35-44"];
            this.testData2.datasets[0].data[6] = females["45-54"];
            this.testData2.datasets[0].data[7] = males["45-54"];
            this.testData2.datasets[0].data[8] = females["55-64"];
            this.testData2.datasets[0].data[9] = males["55-64"];
            this.testData2.datasets[0].data[10] = females["65+"];
            this.testData2.datasets[0].data[11] = males["65+"];
          } //fine if GenderAges

          /////////////controllo le città/////////////////

          if (response.data.fansCities == null) {
            // se non torna nulla
            // TODO Nessun dato, nascondere grafici
          } else {
            // se torna qualcosa
            var fansCitiesArray = Object.entries(response.data.fansCities);
            fansCitiesArray.sort((a, b) => b[1] - a[1]);
            let cnt = 0;

            // Popolo il grafico
            fansCitiesArray.forEach((value, key) => {
              cnt++;
              if (cnt <= 10) {
                let City = value[0];
                City = City.replace(/\b\w/g, function (char) {
                  return char.toUpperCase();
                });
                this.testData3.labels.push(City); // etichetta città
                this.testData3.datasets[0].data[key] = value[1]; // numero iscritti
              }
            });
          }
        })
        .catch((error) => {
          // Gestisci l'errore
          console.log("Errore: " + error);
        });
    },

    updatePageInsights() {
      //chiamata GET all'API
      //axios.get(ApiEndPoints.SOCIAL_FB+'/108895805815369/insights')
      //axios.post(`${ApiEndPoints.SOCIAL_FB}/108895805815369/insights`, {

      axios
        .post(
          `${ApiEndPoints.SOCIAL_FB}/${this.sociaPagelId}/insights`,
          {
            //Inserisci qui i dati da inviare
          },
          {
            // Inserisci qui la configurazione opzionale
          }
        )
        .then((response) => {
          //Gestisci la risposta
          // TODO, lascio console.log perchè deve esserci qualcosa. PB
          console.log("response ok " + response.length);
        })
        .catch((error) => {
          // Gestisci l'errore
          console.log("Errore: " + error);
        });
    },
  },

  components: { DoughnutChart, BarChart },
  setup() {
    onMounted(async () => {
      //this.getPageInsights();
      //this.updatePageInsights();
    });
  },
};
</script>
