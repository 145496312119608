<template>
    <ul class="flex-container flex-col scrollable-content pl24 pb24 fb-list" style="height:50vh">
        <SocialListElement
            v-for="page in fbData.data"
            :key="page.id"
            :social="page"
            mode="trigger"
            @list-select="onSelect"
            :selectedId="selected ? selected.id : ''"
        ></SocialListElement>
    </ul>

</template>
<script>
import {reactive, onMounted, ref } from 'vue'
import SocialListElement from './../SocialListElement'
export default {
    emits:['list-select'],
    props:{
        authResponse:{
            type:Object,
            default:()=>{}
        }
    },
    components:{

        SocialListElement
    },
    setup(props, {emit}){
        let selected = ref(null)
        let fbData = reactive({
            data:[],
            paging:null
        })

        onMounted(async()=>{
            let url = `https://graph.facebook.com/v10.0/${props.authResponse.userID}/accounts?access_token=${props.authResponse.accessToken}&limit=500`
            const response = await fetch(url)
            const { data, paging } = await response.json()
            fbData.data = data
            fbData.paging = paging
        })
        const onSelect = (social) => {
            selected.value = social
            emit('list-select', social)

        }
        return{
            fbData,
            onSelect,
            selected
        }
    }
}
</script>
<style lang="postcss" scoped>
    .fb-list{
        list-style: none;
        padding: 1em;
    }
</style>
