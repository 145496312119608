<template>
    <ResizableColumnContainer 
        v-if="canManageSocials" 
        :maxCol="3" 
        @total-size="onTotalSize"
        class="infotronik_social_container"
    >       <!-- @column-resize="usersColumnResize"  @column-resize="usersColumnResize" :style="listStyle"     @column-resize="usersColumnResize" -->
            <resizable-column v-show="Socialmostralista" 
                :columnIndex="0"
                :className="`flex-container flex-col layout-content w10 innovativestudio_colonne infotronik_social_lista`"
                
                ref="tools"
                :style="toolStyle"
            >
                <social-tools></social-tools>    
            </resizable-column>
            
            <resizable-column v-show="SocialDettaglisocial" 
                :columnIndex="1" 
                :className="`flex-container flex-col layout-content w100 innovativestudio_colonne`" 
            
                ref="list"
                
            >
                <SocialList 
                    :socials="socials" 
                    :selectedId="route.params.id ? parseInt(route.params.id) : 0"
                ></SocialList>
              
            </resizable-column> 
            <resizable-column v-show="SocialDettaglisocial2"
                
                :columnIndex="2" 

                :className="`flex-container flex-col layout-content w100 innovativestudio_colonne infotronik_social_pagina`"
                ref="card" 
                :style="cardStyle"
                :resizable="false"
            >
                <SocialTabs 
                    v-if="social"
                    :social="social" 
                    :key="social.socialId"
                    class="infotronik_home_paginapannello"
                ></SocialTabs>     
                <ColumnPlaceHolder v-else></ColumnPlaceHolder>
            </resizable-column> 
    </ResizableColumnContainer>
</template>

<script>
import { computed,onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import {useCapabilities} from '../utils/Capabilities'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import SocialList from '@/components/social/SocialList'
import SocialTabs from '@/components/social/SocialTabs'
import SocialTools from '@/components/social/SocialTools'
import ColumnPlaceHolder from '@/components/ColumnPlaceHolder'
import {apiSocialFunction} from '@/api/socials'

import {
    tools,
    toolStyle,
    card,
    cardStyle,
    list,
    listStyle,
    onTotalSize,
    totalSize,
    usersColumnResize,
} from '@/utils/ColumnsResizer'

export default {

 created() {    

        ///// sistemo lo colenne a seconda di quelllo che mi arriva 

        this.emitter.on('Socialmostralista', (show) => {
            //alert(show)
            this.Socialmostralista = show;
        });

         this.emitter.on('SocialDettaglisocial', (show) => {
            //alert(show)
            this.SocialDettaglisocial = show;
         
        });

        this.emitter.on('SocialDettaglisocial2', (show) => {
            //alert(show)
            this.SocialDettaglisocial2 = show;
         
        });
        ///////////////////////////////////////////////////////

         /// visualizzazione di base colonne
         this.Socialmostralista = false;
         this.SocialDettaglisocial = true;
         this.SocialDettaglisocial2 =false;


        ///nel caso mi trovo arrivare dai target sistemo le colonne
         if (window.location.pathname.includes("/social/")){

               this.Socialmostralista = false;
               this.SocialDettaglisocial = false;
               this.SocialDettaglisocial2 =true;

               ////////////breadcrumb//////////////
               //creo il breadcrumb per l'utente///
                this.routes = ref([
                { name: 'Socials', path: '/socials' }
                ]);
               ////////////breadcrumb//////////////
               //creo il breadcrumb per l'utente///
        
               this.emitter.emit('breadcrumb',this.routes);
 

        }


 },

    components:{
       ResizableColumn,
       ResizableColumnContainer,
       SocialList,
       SocialTools,
       ColumnPlaceHolder,
       SocialTabs
    },
    setup(){
        const store = useStore()
        const route = useRoute()
                

        const routes = ref([
        { name: ' ', path: '/' }
        ]);
        

        const selected = computed(() => {
           return  store.getters['social/social']
                    .find(social => social.socialId === route.params.id) || null
        })

        const socials = computed(() => {
          return store.getters['social/filtered'] 
            ? store.getters['social/filtered'] 
            : store.getters['social/paged']
        })
         
        const {canManageSocials, isPublisher, isConcessionaire} = useCapabilities()

        const showPublisherCard = isPublisher() || isConcessionaire()

        const selectedTab = ref('social')
        onBeforeRouteLeave(async (to)=>{
            const cardWidth = card?.value?.$el ? card.value.$el.getBoundingClientRect().width +'px' : '70%'
            try {
                store.commit('social/savecolumnsizes',{
                    toolsW:tools.value.$el.getBoundingClientRect().width+'px',
                    listW:list.value.$el.getBoundingClientRect().width+'px',
                    cardW:cardWidth,
                })
            } catch (error) {
                console.log('could not save columns sizes')
            }
            
            if (to.params.id ) {
                social.value = await apiSocialFunction.showFb(to.params.id)
            }else {
                social.value = null
            }
        })
        onBeforeRouteUpdate(async (to, from) => {
            if (to.params.id !== from.params.id ) {
                social.value = await apiSocialFunction.showFb(to.params.id)
            }
            else if (!to.params.id){
                social.value = null
            }
        })

        const social = ref(null)
        onMounted(async()=>{
            if(route.params?.id){
                social.value = await apiSocialFunction.showFb(route.params.id)
            } else {
                social.value = null
            }
            
        })
        
        return {
            route,
            socials,
            social,
            canManageSocials,
            tools,
            list,
            onTotalSize,
            totalSize,
            card,
            toolStyle,
            listStyle,
            cardStyle,
            usersColumnResize,
            selected,
            showPublisherCard,
            selectedTab,
            routes
        }
    }
}
</script>

